<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card card-custom gutter-b example example-compact">
          <div
            class="
              card-header
              d-flex
              align-items-center
              justify-content-between
            "
          >
            <div class="card-title">
              <h3 class="card-label">Litsenziya registratsiyasi</h3>
            </div>
          </div>
          <div class="card-body">
            <div class="d-flex">
              <div class="col-4">
                <v-autocomplete
                  :items="allTrucks"
                  v-model="select"
                  :error-messages="selectErrors"
                  item-text="full__name"
                  item-value="id"
                  :disabled="this.$route.params.id !== undefined"
                  label="Mashina raqami"
                  :loading="isLoading"
                  outlined
                  dense
                  @input="$v.select.$touch()"
                  @blur="$v.select.$touch()"
                ></v-autocomplete>
              </div>

              <div class="col-4">
                <v-text-field
                  :error-messages="reg_numberErrors"
                  v-model="reg_number"
                  label="Reg raqami"
                  outlined
                  dense
                  @input="$v.reg_number.$touch()"
                  @blur="$v.reg_number.$touch()"
                ></v-text-field>
              </div>

              <div class="col-4">
                <v-text-field
                  :error-messages="given_byErrors"
                  v-model="given_by"
                  label="Berilgan tashkilot nomi"
                  outlined
                  dense
                  @input="$v.given_by.$touch()"
                  @blur="$v.given_by.$touch()"
                ></v-text-field>
              </div>
            </div>

            <div class="d-flex">
              <div class="col-4">
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :error-messages="date3Errors"
                      label="Boshlanish sanasi"
                      append-icon="event"
                      readonly
                      :value="date3 | dateFilter"
                      dense
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      @input="$v.date3.$touch()"
                      @blur="$v.date3.$touch()"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date3"
                    @input="menu = false"
                  ></v-date-picker>
                </v-menu>
              </div>

              <div class="col-4">
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :error-messages="date2Errors"
                      label="Tugash sanasi"
                      :value="date2 | dateFilter"
                      append-icon="event"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      dense
                      outlined
                      @input="$v.date2.$touch()"
                      @blur="$v.date2.$touch()"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date2"
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
              </div>

              <div class="col-4">
                <v-autocomplete
                  :items="licenceTypes"
                  v-model="type"
                  :error-messages="typeErrors"
                  item-text="name"
                  item-value="id"
                  label="Sertifikat turi"
                  :loading="isLoading"
                  outlined
                  dense
                  @input="$v.type.$touch()"
                  @blur="$v.type.$touch()"
                ></v-autocomplete>
              </div>
            </div>

            <div>
              <v-btn @click="submit" :disabled="licenceLoading" color="primary"
                ><i v-if="licenceLoading" class="el-icon-loading"></i
                >Saqlash</v-btn
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import Swal from 'sweetalert2'
export default {
  data() {
    return {
      licenceLoading: false,
      type: '',
      menu: false,
      menu2: false,
      date: '',
      date2: '',
      date3: '',
      modal: false,
      modal2: false,
      modal3: false,
      reg_number: '',
      given_by: '',
      select: ''
    }
  },
  validations: {
    type: {
      required
    },
    select: {
      required
    },
    reg_number: {
      required
    },
    given_by: {
      required
    },
    date3: {
      required
    },
    date2: {
      required
    }
  },
  created() {
    this.$store.dispatch('getTruckLists')
    this.$store.dispatch('getLicenceTypes')
  },
  computed: {
    allTrucks() {
      const dataNames = []

      this.$store.state.requests.allTruckList.forEach((element) => {
        const name = {
          full__name: element.reg_number_region + ' ' + element.reg_number,
          id: element.id
        }
        dataNames.push(name)
      })
      return dataNames
    },
    licenceTypes() {
      const data = this.$store.state.requests.licenceTypes
      return data
    },
    selectErrors() {
      const errors = []
      if (!this.$v.select.$dirty) return errors
      !this.$v.select.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    typeErrors() {
      const errors = []
      if (!this.$v.type.$dirty) return errors
      !this.$v.type.required && errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    reg_numberErrors() {
      const errors = []
      if (!this.$v.reg_number.$dirty) return errors
      !this.$v.reg_number.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    given_byErrors() {
      const errors = []
      if (!this.$v.given_by.$dirty) return errors
      !this.$v.given_by.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    date3Errors() {
      const errors = []
      if (!this.$v.date3.$dirty) return errors
      !this.$v.date3.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    date2Errors() {
      const errors = []
      if (!this.$v.date2.$dirty) return errors
      !this.$v.date2.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    }
  },
  methods: {
    submit() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
        Swal.fire({
          title: 'Xato',
          text: "Ma'lumotlar yuborilmadi!",
          icon: 'error',
          confirmButtonClass: 'btn btn-secondary'
        })
      } else {
        this.submitStatus = 'PENDING'
        this.submitStatus = 'OK'
        const data = {
          type: this.type,
          truck: this.select,
          reg_number: this.reg_number,
          given: this.date3,
          start_date: this.date3,
          expires: this.date2,
          given_by: this.given_by
        }
        this.licenceLoading = true
        this.$store
          .dispatch('createLicenses', data)
          .then(() => {
            this.licenceLoading = false
            this.date2 = ''
            this.date3 = ''
            this.reg_number = ''
            this.given_by = ''
            this.select = ''
            this.$v.$reset()
          })
          .catch((err) => {
            this.licenceLoading = false
            console.error(err)
          })
      }
    }
  },
  mounted() {
    if (this.$route.params.id !== undefined) {
      this.select = parseInt(this.$route.params.id, 10)
    }
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Transport' },

      { title: 'Hujjat malumotlari' },

      { title: 'Litsenziya' },
      { title: 'Litsenziya Registratsiyasi' }
    ])
  }
}
</script>
